import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Link from 'components/common/Link'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import { BlockHeader } from 'components/common/BlockHeader'
import { useRouter } from 'next/router'

export const DontDreadBlock = ({ withPartner }) => {
	const { data: tenantProfile } = useTenantProfile()
	const router = useRouter()

	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<Container>
			<BlockHeader
				title={`Don't Dread Your Tax Bill`}
				description={`Opening your annual property tax bill is typically frustrating. It always seems too high, and most owners just pay it without realizing they can lower it. Let us handle the entire protest process so you can rest assured you aren't paying a dollar more than you should on your property taxes each year.`}
			/>
			<Row style={{ marginTop: 60 }}>
				<Col xs='12' md='4' style={{ marginBottom: 30 }}>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Text align='center' size={36} color={secondaryColor}>
							$1.36B
						</Text>
						<Text align='center' size={20} style={{ marginTop: 10 }}>
							Overpaid property taxes in Texas each year
						</Text>
					</div>
				</Col>
				<Col xs='12' md='4' style={{ marginBottom: 30 }}>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Text align='center' size={36} color={secondaryColor}>
							$462M
						</Text>
						<Text align='center' size={20} style={{ marginTop: 10 }}>
							Savings realized for those who protest each year
						</Text>
					</div>
				</Col>
				<Col xs='12' md='4' style={{ marginBottom: 30 }}>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Text align='center' size={36} color={secondaryColor}>
							3-6%
						</Text>
						<Text align='center' size={20} style={{ marginTop: 10 }}>
							Average reduction in tax value
						</Text>
					</div>
				</Col>
			</Row>
			<Row style={{ marginTop: 10 }}>
				<Col style={{ textAlign: 'center' }}>
					<Link to={withPartner ? `/partners/${router.query.slug}/signup` : `/signup`}>
						<Button
							background={primaryColor}
							style={{
								maxWidth: 370,
								padding: 20,
								height: 'auto',
								fontSize: 20,
								fontWeight: 'bold',
							}}
						>
							Lower My Taxes
						</Button>
					</Link>
				</Col>
			</Row>
		</Container>
	)
}
