import React from 'react'
import { Row, Col } from 'react-bootstrap'

import Text from 'components/common/Text'
import { BlockTitle } from './BlockTitle'

export const BlockHeader = ({ title, description }) => {
	return (
		<>
			{title ? (
				<Row>
					<Col>
						<BlockTitle>{title}</BlockTitle>
					</Col>
				</Row>
			) : null}
			{description ? (
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<Text align='center' style={{ marginTop: 20 }}>
							{description}
						</Text>
					</Col>
				</Row>
			) : null}
		</>
	)
}
