import Text from 'components/common/Text'

export const BlockTitle = ({ children }) => {
	return (
		<Text
			size={26}
			style={{
				fontWeight: 'bold',
				textAlign: 'center',
			}}
		>
			{children}
		</Text>
	)
}
