import { DontDreadBlock } from './components/DontDreadBlock'
import { ProtestingIsWorthItBlock } from './components/ProtestingIsWorthItBlock'
import { WhyChooseUsBlock } from './components/WhyChooseUsBlock'
import { BlockContainer } from 'components/common/BlockContainer'
import { HereToHelpBlock } from './components/HereToHelpBlock'
import Page from 'components/common/Page'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import colors from 'utility/colors'
import { HeroBlock } from './components/HeroBlock'
import { useSelf } from 'components/providers/SelfProvider'
import { useRouter } from 'next/router'

export const HomePage = ({ withPartner = false }) => {
	const { data: tenantProfile } = useTenantProfile()
	const { isSignedIn, user } = useSelf()
	const router = useRouter()

	const tenantName = tenantProfile?.name
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	if (isSignedIn) {
		router.replace(user?.baseSlug)
		return null
	}

	return (
		<Page
			title={`Helping homeowners pay fair property taxes in Texas - ${tenantName}`}
			description={`Welcome to ${tenantName} - let us help you get and pay fair property taxes in Texas!`}
			footer
			withPartnerNav={withPartner}
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
				<BlockContainer
					style={{
						background: `linear-gradient(${colors.WHITE}, ${secondaryColor}1A)`,
					}}
				>
					<HeroBlock withPartner={withPartner} />
				</BlockContainer>
				<BlockContainer>
					<DontDreadBlock withPartner={withPartner} />
				</BlockContainer>

				<BlockContainer backgroundColor={`#96A8B51A`}>
					<ProtestingIsWorthItBlock />
				</BlockContainer>

				<BlockContainer>
					<WhyChooseUsBlock />
				</BlockContainer>
				<BlockContainer backgroundColor={`#96A8B51A`}>
					<HereToHelpBlock withPartner={withPartner} />
				</BlockContainer>
			</div>
		</Page>
	)
}
