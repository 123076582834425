import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import { BlockHeader } from 'components/common/BlockHeader'
import Savings from 'assets/inline/Savings.png'
import { IconSection } from './components/IconSection'

export const ProtestingIsWorthItBlock = () => {
	const { data: tenantProfile } = useTenantProfile()
	const tenantName = tenantProfile?.name

	return (
		<Container>
			<BlockHeader
				title='Protesting Is Worth It'
				description={`When owning a property, every bit of savings matters. Protesting your property taxes delivers a 3-6% reduction on average. While that may not sound like much, any reduction this year means you start at a lower value next year. It's compounding savings that adds up!`}
			/>
			<Row style={{ marginTop: 60 }}>
				<Col xs={12} md={{ span: 5 }} style={{ marginBottom: 60 }}>
					<img src={Savings} style={{ width: '100%' }} />
				</Col>
				<Col xs={12} md={{ span: 6, offset: 1 }}>
					<Row>
						<Col xs='6'>
							<IconSection
								icon='dollar-sign'
								title='Tax Savings'
								description='Each year we find every dollar of tax savings you deserve so you keep more of your hard-earned money in your pocket.'
							/>
						</Col>
						<Col xs='6'>
							<IconSection
								icon='home'
								title='Fair Tax Value'
								description='Our AI-powered technology monitors your property and local real estate market to make sure you end up with a fair tax value each year.'
							/>
						</Col>
					</Row>
					<Row style={{ marginTop: 40 }}>
						<Col xs='6'>
							<IconSection
								icon='users'
								title='Our Team, On Your Side'
								description='Our team of local, experienced professionals takes your home through the entire process, negotiating with the county on your behalf.'
							/>
						</Col>
						<Col xs='6'>
							<IconSection
								icon='smile'
								title='Peace of Mind'
								description={`You can rest easy knowing that ${tenantName} has you covered each year, and never again wonder whether you are being fairly taxed.`}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	)
}
