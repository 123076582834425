import Text from 'components/common/Text'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

export const NumberList = ({ number, title }) => {
	const { data: tenantProfile } = useTenantProfile()

	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: 10,
			}}
		>
			<div
				style={{
					width: 26,
					height: 26,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '100%',
					backgroundColor: `${secondaryColor}22`,
				}}
			>
				<Text color={secondaryColor} style={{ marginBottom: 0, paddingBottom: 0 }}>
					{number}
				</Text>
			</div>
			<Text size={14} bold style={{ paddingBottom: 0, marginBottom: 0 }}>
				{title}
			</Text>
		</div>
	)
}
