import React from 'react'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

export const IconSection = ({ icon, title, description }) => {
	const { data: tenantProfile } = useTenantProfile()
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
			<Icon icon={icon} solid color={secondaryColor} size={38} style={{ height: 38 }} />
			<div>
				<Text size={22} bold align='center'>
					{title}
				</Text>
				<Text align='center'>{description}</Text>
			</div>
		</div>
	)
}
