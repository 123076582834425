import Title from 'components/common/Title'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import Text from 'components/common/Text'
import { Container, Row, Col } from 'react-bootstrap'
import PropertyForm from 'components/common/forms/PropertyForm'
import Link from 'components/common/Link'
import colors from 'utility/colors'
import HeroHome from 'assets/inline/HeroHome.jpg'
import { toSlug } from 'utility/string'
import { useRouter } from 'next/router'

export const HeroBlock = ({ withPartner }) => {
	const { data: tenantProfile } = useTenantProfile()
	const router = useRouter()

	const tenantName = tenantProfile?.name
	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<div>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={8} lg={6}>
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<Text size={14} color={secondaryColor} bold style={{ marginBottom: 10 }}>
								Protesting is easy. Because we do it for you.
							</Text>
							<Title large style={{ fontWeight: 'bold', marginBottom: 20 }}>
								Maximize Your Savings with {tenantName}
							</Title>
							<Text style={{ marginBottom: 20 }}>
								At {tenantName}, we're dedicated to helping you reduce your property taxes so you
								can keep more of your hard-earned money.
							</Text>
							<PropertyForm
								withLabels={false}
								promptText={'Enter your home address to start...'}
								height={60}
								inputStyle={{
									padding: 20,
									fontSize: 18,
								}}
								onAdd={(property) => {
									const state = 'texas'
									const slugAddress = toSlug(property?.propertyAddress)
									const searchId = property?.searchId

									router.push({
										pathname: withPartner
											? '/partners/[slug]/[state]/property/[propertyAddress]/[propertySlug]'
											: '/[state]/property/[propertyAddress]/[propertySlug]',
										query: withPartner
											? {
													...router.query,
													slug: router.query.slug,
													state,
													propertyAddress: slugAddress,
													propertySlug: searchId,
											  }
											: {
													...router.query,
													state,
													propertyAddress: slugAddress,
													propertySlug: searchId,
											  },
									})
								}}
							/>

							<Link to='/coverage' target={'_blank'} style={{ display: 'inline' }}>
								<Text
									size={14}
									color={colors.GRAY}
									style={{
										display: 'inline',
										marginTop: 8,
										marginLeft: 8,
										textAlign: 'left',
									}}
								>
									Serving{' '}
									<Text
										style={{
											display: 'inline',
											fontStyle: 'italic',
											fontSize: 14,
											color: primaryColor,
										}}
									>
										these Texas counties
									</Text>
								</Text>
							</Link>
						</div>
					</Col>
					<Col md={4} lg={{ span: 5, offset: 1 }} className={'d-none d-md-block'}>
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<img src={HeroHome} style={{ borderRadius: 10, width: '100%' }} />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
