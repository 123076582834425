import { Container, Row, Col } from 'react-bootstrap'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import { BlockHeader } from 'components/common/BlockHeader'
import { IconSection } from './components/IconSection'

export const WhyChooseUsBlock = () => {
	const { data: tenantProfile } = useTenantProfile()
	const tenantName = tenantProfile?.name

	return (
		<Container>
			<BlockHeader
				title={`Why choose ${tenantName}?`}
				description={`We're local and armed with leading-edge technology & data so you get the results you deserve.`}
			/>

			<Row style={{ marginTop: 60 }}>
				<Col xs={12} md={4} style={{ marginBottom: 20 }}>
					<IconSection
						icon='user-tie'
						title='Local Tax Experts'
						description='We leverage our network to get your case in the hands of a licensed tax professional.'
					/>
				</Col>
				<Col xs={12} md={4} style={{ marginBottom: 20 }}>
					<IconSection
						icon='laptop-code'
						title='Industry-leading Technology'
						description='We leverage advanced data & technology to maximize your tax reduction and deliver you a wonderful user experience.'
					/>
				</Col>
				<Col xs={12} md={4} style={{ marginBottom: 20 }}>
					<IconSection
						icon='sunglasses'
						title='Hassle-Free Process'
						description='Property taxes are complex and stressful. We handle everything so you can rest easy.'
					/>
				</Col>
			</Row>
		</Container>
	)
}
