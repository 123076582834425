export const BlockContainer = ({ children, backgroundColor, style }) => {
	return (
		<div
			style={{
				backgroundColor,
				paddingTop: 60,
				paddingBottom: 60,
				...style,
			}}
		>
			{children}
		</div>
	)
}
