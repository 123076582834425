import React from 'react'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

export const IconSection = ({ icon, title, description }) => {
	const { data: tenantProfile } = useTenantProfile()

	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
			<Icon icon={icon} solid color={secondaryColor} size={30} style={{ height: 30 }} />
			<div>
				<Text size={22} bold>
					{title}
				</Text>
				<Text>{description}</Text>
			</div>
		</div>
	)
}
