import { Container, Row, Col } from 'react-bootstrap'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import Button from 'components/common/Button'
import Text from 'components/common/Text'
import { NumberList } from './components/NumberList'
import Link from 'components/common/Link'
import { useRouter } from 'next/router'

export const HereToHelpBlock = ({ withPartner }) => {
	const router = useRouter()
	const { data: tenantProfile } = useTenantProfile()

	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return (
		<Container>
			<Row>
				<Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
					<div style={{ marginBottom: 30 }}>
						<Text
							size={16}
							color={secondaryColor}
							style={{
								fontWeight: 'bold',
								marginBottom: 0,
								paddingBottom: 0,
							}}
						>
							We're here to help!
						</Text>
						<Text
							size={26}
							style={{
								fontWeight: 'bold',
							}}
						>
							Take Control of Your Property Taxes Today
						</Text>
					</div>
					<Link to={withPartner ? `/partners/${router.query.slug}/signup` : `/signup`}>
						<Button
							background={primaryColor}
							style={{
								width: 'auto',
								paddingLeft: 20,
								paddingRight: 20,
							}}
						>
							Get Started
						</Button>
					</Link>
				</Col>
				<Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }} style={{ marginBottom: 40 }}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
						<NumberList number={1} title={'Sign up'} />
						<NumberList number={2} title={'We handle the entire process'} />
						<NumberList number={3} title={'You save money!'} />
					</div>
				</Col>
			</Row>
		</Container>
	)
}
